import React, { useRef, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Theme, ButtonBase } from '@material-ui/core/'
import { KeyboardArrowDown } from '@material-ui/icons'
import clsx from 'clsx'
import LinkWrapper from '@src/aem-content/components/LinkWrapper'
import HandleMarkup from '@src/components/HandleMarkup'
import { useRouter } from '@src/routes'
import { parseRegionalUrl } from '@src/utils/regional'

const hoverClickDelayDefault = 300

interface MenuButtonProps {
  label?: string
  active?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  menuItem?: boolean
  linkProps?: any
  hoverDelay?: number
  classes: any
  setState?: () => void
  handleAnalyticsEvent?: (ga4Only?: boolean) => void
  closeNav?: () => void
  level?: string
}

const MenuButton: React.FC<MenuButtonProps> = (props) => {
  const {
    label = '',
    active = false,
    onClick = () => {
      // Added comment for avoid es-lint error
    },
    // Function to set state when the item is not a menuItem (with children)
    setState = () => {
      // Added comment for avoid es-lint error TopNavButton-MenuButton
    },
    menuItem = true,
    linkProps = {},
    hoverDelay,
    classes,
    handleAnalyticsEvent = () => {
      // Added comment for avoid es-lint error
    },
    closeNav,
    level,
  } = props

  const { linkUrl } = linkProps
  const handleClick =
    !linkUrl || menuItem
      ? onClick
      : () => {
          // Added comment for avoid es-lint error
        }
  const buttonRef = useRef<HTMLButtonElement>(null)
  const [delayHandler, setDelayHandler] =
    useState<ReturnType<typeof setTimeout>>()

  // AEM3-1895 - Some countries(e.g AD,GL) that come under INTL in cms-commons do not have pages specifically created for those countries.
  // Hence BE sends a redirect url for the navigation pages of those countries.Because the country/language of the redirect url would be
  // different from the user country, they need to be updated so that the page redirects correctly.
  const router = useRouter()
  const {
    basename: { country, language },
  } = parseRegionalUrl(router.asPath)
  const {
    basename: { country: navCountry },
    pathAfterBasename,
  } = parseRegionalUrl(linkUrl)
  if (navCountry && country !== navCountry && pathAfterBasename)
    linkProps.linkUrl = `/${country}/${language}${pathAfterBasename}`
  const handleMouseEnter = () => {
    menuItem &&
      !active &&
      setDelayHandler(
        setTimeout(
          () => !active && buttonRef.current?.click(),
          hoverDelay != null ? hoverDelay : hoverClickDelayDefault
        )
      )
  }

  const handleMouseLeave = () => {
    delayHandler && clearTimeout(delayHandler)
  }

  if (active) {
    handleMouseLeave()
  }

  // Delay for menu items which don't have subitems
  const [leaveDelay, setLeaveDelay] = useState<ReturnType<typeof setTimeout>>()
  const leaveDelayMouseEnter = () => {
    setLeaveDelay(
      // update menu state so we remove subitem elements from other items
      setTimeout(setState, 300)
    )
  }
  const leaveDelayMouseLeave = () => {
    leaveDelay && clearTimeout(leaveDelay)
  }

  return (
    <>
      {menuItem ? (
        <ButtonBase
          ref={buttonRef}
          aria-expanded={active}
          onClick={handleClick}
          className={clsx(classes.root, { [classes.active]: active })}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <span
            className={clsx(classes.wrapper, { [classes.active]: active })}
            onClick={() => handleAnalyticsEvent(true)}
          >
            {linkUrl && (
              <LinkWrapper
                className={classes.link + ` aem-navlinks-level-${level}`}
                linkLabel={label}
                closeNav={closeNav}
                {...linkProps}
              />
            )}
            {!linkUrl && (
              <HandleMarkup value={label} className={classes.label} />
            )}
            <KeyboardArrowDown
              className={clsx(classes.icon, { [classes.active]: active })}
            />
          </span>
        </ButtonBase>
      ) : (
        <div
          className={clsx(classes.root, { [classes.active]: active })}
          onMouseEnter={leaveDelayMouseEnter}
          onMouseLeave={leaveDelayMouseLeave}
        >
          {/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
          <span
            className={clsx(classes.wrapper, { [classes.active]: active })}
            onClick={() => handleAnalyticsEvent()}
          >
            <LinkWrapper
              className={classes.link + ` aem-navlinks-level-${level}`}
              linkLabel={label}
              closeNav={closeNav}
              {...linkProps}
            />
          </span>
        </div>
      )}
    </>
  )
}

export const MainNavButton = withStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: '#FFFFFF',
    },
    '&$active': {
      color: theme.palette.text.primary,
      backgroundColor: '#FFFFFF',
    },
  },
  wrapper: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 16px',
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 900,
  },
  icon: {
    '&$active': {
      transform: 'rotate(180deg)',
    },
  },
  label: {},
  link: {},
  active: {},
}))(MenuButton)

export const SubNavButton = withStyles((theme: Theme) => ({
  root: {
    padding: '8px 14px 8px 20px',
  },
  wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'start',
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'left',
    '&$active, &:hover': {
      fontWeight: 900,
      letterSpacing: '-0.145px',
    },
  },
  icon: {
    transform: 'rotate(-90deg)',
  },
  link: {
    width: '100%',
    color: theme.palette.text.primary,
    fontWeight: 'normal',
    '&:hover': {
      fontWeight: 900,
      color: theme.palette.text.primary,
    },
  },
  label: {
    paddingRight: '16px',
  },
  active: {
    '& > *': {
      fontWeight: 900,
      textDecoration: 'none',
    },
  },
}))(MenuButton)
